window.onerror = function (msg, url, line) {
	fetch('/api/javascripterror', {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		method: 'POST',
		body: `msg=${msg}&url=${url}&line=${line}&appName=${window.navigator.appName}&appCodeName=${window.navigator.appCodeName}&appVersion=${window.navigator.appVersion}`,
	}).finally(() => {
		console.log('Error logged on server');
	});
};
